import App from './App'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import React from 'react'
import { hydrate } from 'react-dom'
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { typePolicies } from './common/typePolicy'
import { HelmetProvider } from 'react-helmet-async'
import { loadableReady } from "@loadable/component";

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.RAZZLE_GQL_HOST
  }),
  cache: new InMemoryCache({ typePolicies }).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100, // in milliseconds
})

const app = (
  <HelmetProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App url={window.location.host.replace('-origin', '')} />
      </BrowserRouter>
    </ApolloProvider>
  </HelmetProvider>
)

loadableReady().then(() => {
  hydrate(app, document.getElementById("root"));
});

if (module.hot) {
  module.hot.accept()
}
