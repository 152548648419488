import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { Link } from 'react-router-dom'
import { variables, Heading, NavIcon, MoreLink } from '@website2018/da-dobsonville'

const ContactInfo = props => {
  const { title, iconColor, color, settings } = props
  const { spacing, colors } = variables

  const styles = StyleSheet.create({
    menu: {
      margin: spacing.space0,
      padding: spacing.space0
    }
  })

  if (!settings) return null

  return (
    <Fragment>
      <Heading
        color={color}
        size={'tiny'}
        weight={'bold'}
        letterSpacing={'small'}
        mb={'tiny'}
        uppercase
      >
        {title}
      </Heading>
      <div className={css(styles.menu)}>
        <a
          href={settings.social.telNumber}
          target="_blank"
          rel="noopener noreferrer"
          title={settings.social.telNumber}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialMobile}
            iconName="telephone-fill"
          >
            {settings.social.telText}
          </NavIcon>
        </a>
        {settings.social.whatsapp && (
          <a
            href={settings.social.whatsapp}
            target="_blank"
            rel="noopener noreferrer"
            title={settings.social.whatsapp}
          >
            <NavIcon
              iconType="bootstrap"
              color={color}
              iconColor={iconColor ? iconColor : colors.socialWhatsApp}
              iconName="whatsapp"
            >
              {settings.social.whatsappText}
            </NavIcon>
          </a>
        )}

        <a
          href={settings.social.instagram}
          target="_blank"
          rel="noopener noreferrer"
          title={settings.social.instagram}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialInstagram}
            iconName="instagram"
          >
            Instagram
          </NavIcon>
        </a>
        <a
          href={settings.social.facebook}
          target="_blank"
          rel="noopener noreferrer"
          title={settings.social.facebook}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialFacebook}
            iconName="facebook"
          >
            Facebook
          </NavIcon>
        </a>
        <a
          href={settings.social.twitter}
          target="_blank"
          rel="noopener noreferrer"
          title={settings.social.twitter}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialTwitter}
            iconName="twitter-x"
          >
            X (Twitter)
          </NavIcon>
        </a>
        <a
          href={settings.social.youtube}
          target="_blank"
          rel="noopener noreferrer"
          title={settings.social.youtube}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialYoutube}
            iconName="youtube"
          >
            YouTube
          </NavIcon>
        </a>
        <a
          href={settings.social.email}
          target="_blank"
          rel="noopener noreferrer"
          title={settings.social.email}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialEmail}
            iconName="envelope-fill"
          >
            Email
          </NavIcon>
        </a>
        <Link to={`/contact`}>
          <MoreLink color={color} uppercase>
            View all Info
          </MoreLink>
        </Link>
      </div>
    </Fragment>
  )
}

ContactInfo.defaultProps = {
  title: 'Contact Details',
  color: variables.colors.brandBlue,
  iconColor: null
}

ContactInfo.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string
}

export default ContactInfo
