export const typePolicies = {
  Query: {
    fields: {
      provincial: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
}