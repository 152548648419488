import React, { div } from 'react'
import {
  variables,
  NavbarSecondary,
  NavItem,
  NavIcon,
  MoreLink,
  Icon,
  Navbar,
  Logo,
  ButtonTest
} from '@website2018/da-dobsonville'
import Sticky from 'react-stickynode'
import { Link, withRouter } from 'react-router-dom'
import { StyleSheet, css } from 'glamor/aphrodite'

const styles = StyleSheet.create({
  searchWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    background: variables.colors.brandWhite,
    width: '100%'
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  searchInput: {
    fontFamily: variables.fonts.sans,
    borderRadius: '0px',
    paddingTop: '5px',
    paddingBottom: '5px',
    border: 'none',
    color: variables.colors.textBase,
    ':focus': {
      border: 'none',
      boxShadow: 'none'
    },
    '@media (min-width: 768px)': {
      paddingTop: '12px',
      paddingBottom: '12px'
    }
  }
})

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      search: false,
      term: '',
      showCondensed: false
    }

    this.links = [
      { label: 'Why the DA?', link: '/why-the-DA', desktop: true },
      { label: 'Where we Govern', link: '/where-we-govern', desktop: true },
      { label: 'Our People', link: '/our-people', desktop: true },
      { label: 'Newsroom', link: '/newsroom', desktop: true },
      { label: 'Contact', link: '/contact', desktop: true },
      { label: 'Campaigns', link: '/campaigns', desktop: false }
    ]
  }

  handleSearch = () => {
    this.props.history.push(`/newsroom?query=${this.state.term}`)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        open: false
      })
      window.scrollTo(0, 0)
    }
  }

  handleKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        this.handleSearch()
        break

      case 'Escape':
        this.nameInput.blur()
        break
      default:
        break
    }
  }

  handleOpenSearch = () => {
    this.setState({
      search: true,
      term: ''
    })
    setTimeout(() => {
      this.nameInput.focus()
    }, 200)
  }

  handleCloseSearch = () => {
    this.setState({
      search: false,
      term: ''
    })
  }

  clearSearch = () => {
    this.setState({
      term: ''
    })
  }

  handleChange = event => {
    const { value } = event.target
    const term = value.toLowerCase()

    if (!value) {
      this.clearSearch()
      return
    }

    this.setState({
      term,
      value
    })
  }

  handleStateChange = status => {
    if (status.status === Sticky.STATUS_FIXED) {
      this.setState({
        showCondensed: false
      })
    } else {
      this.setState({
        showCondensed: false
      })
    }
  }

  renderSearch() {
    const { colors, spacing } = variables

    if (this.state.search) {
      return (
        <div className={`${css(styles.searchWrapper)}`}>
          <div className={`container`}>
            <div className={`${css(styles.search)}`}>
              <input
                className={`form-control ${css(styles.searchInput)}`}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                ref={input => {
                  this.nameInput = input
                }}
                id="search-box"
                placeholder="Search and press 'Enter'"
                value={this.state.term}
                type="text"
              />
              <NavIcon
                onPress={() => this.handleSearch()}
                className="search_form--submit"
                mb="none"
              >
                <Icon key="search" name="search" />
              </NavIcon>
              <NavIcon
                onPress={() => this.handleCloseSearch()}
                className="search_form--close"
                mb="none"
              >
                <Icon name="close" fs="20px" color={colors.textBase} mr={spacing.space1} />
              </NavIcon>
            </div>
          </div>
        </div>
      )
    }

    return null
  }

  renderHrefButton(navItem, button, link, text) {
    return (
      <NavItem {...navItem} title={text} href={link}>
        <ButtonTest {...button}>{text}</ButtonTest>
      </NavItem>
    )
  }

  renderToButton(navItem, button, link, text) {
    return (
      <NavItem {...navItem}>
        <Link to={link}>
          <ButtonTest {...button}>{text}</ButtonTest>
        </Link>
      </NavItem>
    )
  }

  renderSearchIcon(navItem, navIcon) {
    const { spacing } = variables
    return (
      <NavItem {...navItem}>
        <NavIcon onPress={() => this.handleOpenSearch()} mb="none" {...navIcon}>
          <Icon name="search" mr={spacing.space0} ml={spacing.space0} />
        </NavIcon>
      </NavItem>
    )
  }

  renderSocialItem(title, icon, link, navProps, iconProps) {
    const { spacing, colors } = variables
    const navItem = {
      color: colors.textLightO,
      colorHover: colors.white,
      fs: '18px',
      ...navProps
    }
    const iconItem = {
      mr: spacing.space0,
      ml: spacing.space0,
      ...iconProps
    }
    return (
      <NavItem {...navItem} target="_blank" title={`Link to ${title}`} href={link}>
        <Icon iconType="bootstrap" name={icon} {...iconItem} />
      </NavItem>
    )
  }

  openMenu = () => {
    this.setState(state => ({
      open: !state.open
    }))
  }

  render() {
    const { colors, spacing, fontWeight } = variables
    const {
      site: { province, options = {} }
    } = this.props
    const donatelink = options.structureCode === 'ZN' ? 'https://donate.da.org.za/p/donate-to-the-da-to-rescue-kzn?ref=WEBoptions' : 'https://donate.da.org.za/?ref=WEBoptions'

    return (
      <nav>
        {this.renderSearch()}
        <div className="mobileTop">
          <NavbarSecondary>
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              mr={'auto'}
              ml={spacing.space0}
            >
              <MoreLink
                color={'white'}
                arrowDirection="left"
                lowercase
                weight={'regular'}
                tracking={'small'}
                size={'medium'}
                href={'https://www.da.org.za'}
              >
                Visit da.org.za
              </MoreLink>
            </NavItem>
            {this.renderSearchIcon(
              { color: colors.brandWhite, fs: '18px', ml: 'auto' },
              { color: colors.textLightO }
            )}
            {options.facebook
              ? this.renderSocialItem('Facebook', 'facebook', options.facebook, {}, {})
              : null}
            {options.instagram
              ? this.renderSocialItem('Instagram', 'instagram', options.instagram, {}, {})
              : null}
            {options.twitter
              ? this.renderSocialItem('X (Twitter)', 'twitter-x', options.twitter, {}, {})
              : null}
            {options.youtube
              ? this.renderSocialItem('YouTube', 'youtube', options.youtube, {}, {})
              : null}
          </NavbarSecondary>
        </div>
        <div className="desktopTop">
          <NavbarSecondary>
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              mr={'auto'}
              ml={spacing.space0}
            >
              <MoreLink
                color={'white'}
                arrowDirection="left"
                lowercase
                weight={'regular'}
                tracking={'small'}
                size={'medium'}
                href={'https://www.da.org.za'}
              >
                Visit da.org.za
              </MoreLink>
            </NavItem>
            {this.renderSearchIcon(
              { ml: 'auto', fs: '16px', lh: '16px' },
              { color: colors.brandWhite }
            )}
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              {options.about !== 'false' ? (
                <Link to={`/about`}>About</Link>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.da.org.za/why-the-da"
                >
                  About
                </a>
              )}
            </NavItem>
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/our-people`}>Our People</Link>
            </NavItem>
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/newsroom`}>Newsroom</Link>
            </NavItem>
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              {options.wwg !== 'false' ? (
                <Link to={`/where-we-govern`}>Where we Govern</Link>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.da.org.za/where-we-govern"
                >
                  Where we Govern
                </a>
              )}
            </NavItem>
            <NavItem
              fs={'13px'}
              lh={'16px'}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/contact`}>Contact</Link>
            </NavItem>
          </NavbarSecondary>
        </div>

        <Sticky // top
          enabled={true}
          top={0}
          onStateChange={this.handleStateChange}
          innerZ="9999"
        >
          <div>
            <div className="desktopTop">
              <Navbar spacing={this.state.showCondensed ? 'small' : 'medium'}>
                <NavItem mr={'auto'} ml={spacing.space0} lh={'0'}>
                  <Link to="/">
                    <Logo
                      division={province.title.rendered}
                      size={this.state.showCondensed ? '87px' : '130px'}
                    />
                  </Link>
                </NavItem>
                {options.facebook
                  ? this.renderSocialItem(
                      'Facebook',
                      'facebook',
                      options.facebook,
                      { fs: '24px' },
                      {}
                    )
                  : null}
                {options.instagram
                  ? this.renderSocialItem(
                      'Instagram',
                      'instagram',
                      options.instagram,
                      { fs: '24px' },
                      {}
                    )
                  : null}
                {options.twitter
                  ? this.renderSocialItem('Twitter', 'twitter-x', options.twitter, { fs: '24px' }, {})
                  : null}
                {options.youtube
                  ? this.renderSocialItem('YouTube', 'youtube', options.youtube, { fs: '24px' }, {})
                  : null}

                <NavItem
                  fontWeight={fontWeight.bold}
                  color={colors.textLightO}
                  colorHover={colors.textWhite}
                >
                  <Link to={`/campaigns`}>Campaigns</Link>
                </NavItem>
                {this.renderHrefButton(
                  {
                    fontWeight: fontWeight.bold,
                    color: colors.linkBlue,
                    mr: spacing.space0
                  },
                  { color: 'green' },
                  donatelink,
                  'Donate'
                )}
                {this.renderHrefButton(
                  {
                    fontWeight: fontWeight.bold,
                    color: colors.linkBlue,
                    mr: spacing.space0
                  },
                  { color: 'white', outline: true },
                  'https://www.da.org.za/get-involved',
                  'Get Involved'
                )}
              </Navbar>
            </div>

            <div className="mobileTop">
              <Navbar>
                <NavItem lh={'0'}>
                  <Link to="/">
                    <Logo size="80px" division={province.title.rendered} />
                  </Link>
                </NavItem>
                <NavItem ml="auto" fontWeight={fontWeight.bold} color={colors.linkBlue}>
                  <ButtonTest
                    onClick={this.openMenu}
                    color="white"
                    outline
                    iconBefore={this.state.open ? 'close' : 'bars'}
                    size="small"
                  >
                    Menu
                  </ButtonTest>
                </NavItem>
              </Navbar>
            </div>

            {this.state.open ? (
              <div>
                <Navbar align="vertical" bgColor={'light'}>
                  {this.links.map(item => (
                    <NavItem
                      fs={'13px'}
                      lh={'16px'}
                      fontWeight={fontWeight.medium}
                      mt={spacing.space1}
                      mb={spacing.space2}
                      color={colors.linkBlue}
                      ml="0"
                      mr="0"
                    >
                      <Link to={`${item.link}`}>{item.label}</Link>
                    </NavItem>
                  ))}
                  <NavItem
                    fs={'13px'}
                    lh={'16px'}
                    fontWeight={fontWeight.medium}
                    mt={spacing.space1}
                    mb={spacing.space2}
                    color={colors.linkBlue}
                    ml="0"
                    mr="0"
                  >
                    <Link to={'/campaigns'}>{'Campaigns'}</Link>
                  </NavItem>
                  {this.renderHrefButton(
                    {
                      mt: spacing.space1,
                      mb: spacing.space1,
                      ml: spacing.space0
                    },
                    { color: 'green' },
                    donatelink,
                    'Donate'
                  )}
                  {this.renderHrefButton(
                    {
                      mt: spacing.space1,
                      mb: spacing.space1,
                      ml: spacing.space0
                    },
                    { color: 'blue', outline: true },
                    'https://www.da.org.za/get-involved',
                    'Get Involved'
                  )}
                </Navbar>
              </div>
            ) : null}
          </div>
        </Sticky>
      </nav>
    )
  }
}

export default withRouter(Header)
